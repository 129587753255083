import { useQuery } from '@tanstack/vue-query'
import { GET_CURRENCIES_QUERY } from '@autobid/ui/constants/queries/GET_CURRENCIES_QUERY'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'

type Currency = {
  id: string
  exchange: number
}

interface Resp {
  data: {
    currencies: {
      items: Currency[]
    }
  }
}

export const USE_CURRENCIES_BODY = {
  query: GET_CURRENCIES_QUERY,
  queryCK: 'common:currencies',
  queryCKE: 24 * 60 * 60
}

export const USE_CURRENCIES_TANSTACK_KEY = ['currencies']

export const useCurrencies = (enabled: boolean) => {
  const headers = useRequestHeaders(['cookie'])
  const { $customFetch } = useCustomFetch()
  const fetchFn = async () => {
    return await $customFetch<Resp>('/api/currencies', headers)
  }

  return useQuery(USE_CURRENCIES_TANSTACK_KEY, fetchFn, {
    enabled,
    refetchOnWindowFocus: false
  })
}
