<template>
  <component :is="as ?? 'span'" class="flex flex-wrap items-center">
    <span class="block pr-2"> {{ formatPrice(price) }}</span>
    <span
      v-if="myCurrencyPrice && !hideCurrentPrice"
      class="pdf-hide block text-sm"
    >
      ({{ myCurrencyPrice }})
    </span>
  </component>
</template>

<script lang="ts" setup>
import { useUserPrice } from '@autobid/ui/composables/useUserPrice'
import { formatPrice } from '@autobid/ui/utils/formatPrice'
import type { Component } from 'vue'

interface Props {
  price: number
  as?: Component
  hideCurrentPrice?: boolean
}

const props = defineProps<Props>()

const formatUserPrice = useUserPrice()
const myCurrencyPrice = computed(() => {
  return formatUserPrice(props.price)
})
</script>
